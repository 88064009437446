import React from 'react';
import '../styles/tokenComponent.css';
import { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../Context/WalletContext';
import VES_symbol from '../logos/Grouplogo.png';


function TokenComponent({ handleEthChange, handleVesChange, ethQuantity, vesQuantity, estimatedCost }) {
  const { connected, connectWallet, balance, rate, price, supply, ethPrice } = useContext(WalletContext);

  //const displayPrice = price ? price.toFixed(2) : '--'; // Ensure price is a number and format it

  return (
    <div className="token-container">
      <div className="token-section">
        <div className="token-card">
          <div className="token-card-content">
            <button className="token-button">
              <img
                className='token-icon'
                alt="Token Icon"
                src="https://raw.githubusercontent.com/ThrusterX/thruster-token-list/main/chains/81457/assets/0x0000000000000000000000000000000000000000/token-logo.svg"
              />
              <p className="truncate">ETH</p>
              <div>
                {/* <svg
                  className="fill-gray-80"
                  fill="inherit"
                  height="16"
                  viewBox="0 0 18 18"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  color="inherit"
                  name="chevron-down"
                  size="16"
                >
                  <path
                    clipRule="evenodd"
                    d="M14.378 6.997c.33.33.33.864 0 1.193l-4.781 4.782a.844.844 0 0 1-1.194 0L3.623 8.19a.844.844 0 1 1 1.192-1.193L9 11.182l4.185-4.185a.844.844 0 0 1 1.193 0"
                    fillRule="evenodd"
                  ></path>
                </svg> */}
              </div>
            </button>
            <div className="token-balance">
              <span>{balance}</span>
            </div>
          </div>
          <div className="token-input-container">
            <div className="token-input-wrapper">
              <input
                className="token-input"
                placeholder={ethQuantity ? ethQuantity : "0"}  
                type="number"
                value={ethQuantity}
                onChange={handleEthChange}
                disabled = {!connected}
              />
            </div>
            <div className="token-estimation">estimated cost: ${connected ? estimatedCost : '0'}</div>
          </div>
        </div>

        <div className="token-card">
          <div className="token-card-content">
            <button className="token-button">
              <img
                alt="Token Icon"
                src={VES_symbol}
                className='token-icon'
              />
              <p className="truncate">VES</p>
              <div>
                {/* <svg
                  className="fill-gray-80"
                  fill="inherit"
                  height="16"
                  viewBox="0 0 18 18"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  color="inherit"
                  name="chevron-down"
                  size="16"
                >
                  <path
                    clipRule="evenodd"
                    d="M14.378 6.997c.33.33.33.864 0 1.193l-4.781 4.782a.844.844 0 0 1-1.194 0L3.623 8.19a.844.844 0 1 1 1.192-1.193L9 11.182l4.185-4.185a.844.844 0 0 1 1.193 0"
                    fillRule="evenodd"
                  ></path>
                </svg> */}
              </div>
            </button>
            <div className="token-balance">
              <span>Tokens on sale: {supply}</span>
            </div>
            <div>
            </div>
          </div>
          <div className="token-input-container">
            <div className="token-input-wrapper">
              <input
                className="token-input"
                placeholder="0.00"
                type="number"
                value={vesQuantity}
                onChange={handleVesChange}
                disabled = {!connected}

              />
            </div>
            <div className="token-estimation">VES price: ${rate}</div>
          </div>
        </div>

        {/* <button className="token-arrow-button">
          <div>
            <svg
              className="token-arrow-icon"
              fill="none"
              height="27"
              viewBox="0 0 28 16"
              width="27"
              xmlns="http://www.w3.org/2000/svg"
              color="inherit"
              name="thruster-grad-arrow-down"
              size="27"
            >
              <g clipRule="evenodd" fillRule="evenodd">
                <path
                  d="M4.216 1.65c1.934.865 5.07 1.759 9.783 1.759 4.715 0 7.85-.894 9.784-1.76.969-.433 1.64-.862 2.061-1.173a6 6 0 0 0 .548-.456l.017-.018v.001l.063.063 1.028.986L14.028 16 .5 1.053 1.513.08 1.59.003 1.589.001h-.001.001l.018.019q.028.028.096.09c.092.084.24.21.451.366.422.311 1.093.74 2.061 1.173m.564 1.877 8.494 9.385-.02-7.974C9.54 4.865 6.774 4.25 4.78 3.527m9.969 1.41.02 7.98 8.47-9.398c-1.994.726-4.766 1.345-8.49 1.419M26.409.003 26.411 0z"
                  fill="url(#thruster-gradient-arrow-down_svg__a)"
                ></path>
              </g>
              <defs>
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  id="thruster-gradient-arrow-down_svg__a"
                  x1="29.375"
                  x2="13.451"
                  y1="17"
                  y2="-8.716"
                >
                  <stop stopColor="#FFD7D6"></stop>
                  <stop offset="1" stopColor="#FF8580"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </button> */}
      </div>

      {/* <button className="connect-wallet-button" disabled>
        <span className="truncate">Connect Wallet</span>
      </button> */}


    </div>
  );
}

export default TokenComponent;
