import React, { useState, useEffect } from 'react';
import '../styles/AboutHelperPage.css';
import RoadmapOne from '../assets/roadmap_1.png';
import RoadmapTwo from '../assets/roadmap_2.png';
import RoadmapThree from '../assets/roadmap_3.png';
import RoadmapFour from '../assets/roadmap_4.png';
import RoadmapFive from '../assets/roadmap_5.png';
import RoadmapSix from '../assets/roadmap_6.png';

const Data = [
    {
        heading: "ABOUT VESTION",
        body: "Welcome to Vestion, an innovative Ethereum-based token bridging the worlds of cryptocurrency and traditional investments. At Vestion, we believe in the power of blockchain technology to revolutionize the way investments are made and managed, creating opportunities for individuals and institutions alike to access a diverse range of assets and opportunities.",
        image: RoadmapOne
    },
    {
        heading: "OUR VISION",
        body: "Our vision at Vestion is to create a seamless connection between the digital and physical worlds of finance. We envision a future where anyone, anywhere, can easily invest in real-world assets, powered by the transparency, security, and efficiency of blockchain technology. By leveraging the Ethereum network, we aim to democratize access to investment opportunities and unlock new avenues for wealth creation.",
        image: RoadmapTwo
    },
    {
        heading: "TOKENOMICS",
        body: "Vestion operates on the Ethereum blockchain, utilizing smart contracts to facilitate secure and transparent transactions. The Vestion token (VES) serves as both a medium of exchange and a store of value within our ecosystem. As a deflationary token, VES is designed to increase in value over time, driven by both market demand and the performance of the real-world assets it represents.",
        image: RoadmapThree
    },
    {
        heading: "REAL WORLD INVESTMENTS",
        body: "Unlike purely speculative tokens, Vestion derives its value from tangible assets and investments in the real world. Through strategic partnerships and expert asset management, we allocate funds raised through token sales to a diversified portfolio of assets, including but not limited to stocks, bonds, real estate, and commodities. The performance of these assets directly impacts the value of the VES token, providing investors with a tangible connection to the underlying investments.",
        image: RoadmapFour
    },
    {
        heading: "TRANSPARENCY AND SECURITY",
        body: "At Vestion, transparency and security are paramount. We provide regular updates and reports on the performance of our investment portfolio, ensuring that investors have full visibility into how their funds are being managed. Our smart contracts are audited by reputable third-party firms to guarantee their integrity and reliability, giving investors peace of mind knowing that their assets are protected.",
        image: RoadmapFive
    },
    {
        heading: "COMMUNITY AND GOVERNANCE",
        body: "Vestion is more than just a token—it's a community of like-minded individuals united by a shared vision of financial empowerment. We believe in the importance of community input and governance, allowing VES holders to participate in key decisions regarding the future direction of the project. Through open communication channels and democratic governance structures, we empower our community to shape the evolution of Vestion.",
        image: RoadmapSix
    },
];

const AboutHelperPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="about-helper-column">
            {Data.map((item, index) => (
                <div className="about-row" key={index}>
                    
                    {
                        isMobile ?
                        <>
                        <div className="text-content">
                            <h1 className='about-heading'>{item.heading}</h1>
                            <p>{item.body}</p>
                        </div>
                        <img src={item.image} alt={`Image ${index + 1}`} />
                    </>
                        :
                     index % 2 === 0 ? (
                            <>
                                <div className="text-content">
                                    <h1 className='about-heading'>{item.heading}</h1>
                                    <p>{item.body}</p>
                                </div>
                                <img src={item.image} alt={`Image ${index + 1}`} />
                            </>
                        ) : (
                            <>
                                <img src={item.image} alt={`Image ${index + 1}`} />
                                <div className="text-content">
                                    <h1 className='about-heading'>{item.heading}</h1>
                                    <p>{item.body}</p>
                                </div>
                            </>
                        )
                    }

                </div>
            ))}
        </div>
    );
};

export default AboutHelperPage;
