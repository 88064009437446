import React from 'react';
// import { BiColor } from 'react-icons/bi';
// import Roadmap from '../common/RoadMap'; 
import AboutHelperPage from './about_helper'; 
import '../styles/AboutHelperPage.css';


const AboutPage = () => (
  <div>
    <center>
      <br/>
    <h2 className='about-title-no-color'>WHO WE ARE</h2>
    <h2 className='about-title'>ABOUT US</h2>
    </center>
<AboutHelperPage/>
  </div>
);

export default AboutPage;
