import React, { useState } from 'react';
import './breadcrumbs.css'; // Add the corresponding CSS styles here
import { FaBars } from 'react-icons/fa6'; // Icons for breadcrumb and close button
import { FaTimes } from 'react-icons/fa';
import { title } from 'process';
import { Link } from 'react-router-dom';


const Breadcrumb = ({ addTokenToMetaMask }) => {
    const [isOpen, setIsOpen] = useState(false);
    const items = ['About Us', 'Whitepaper', 'Terms of use', 'Privacy Policy', 'Cookies Policy'];
    const footerUrls = {
        "cookies": {
            title: 'Cookies Policy',
            url: "https://docs.google.com/document/d/131txHFjmu1FqJKLEwjsy-99R6XftADJI0kVcYjsmNvY/edit"
        },
        "privacy": {
            title: 'Privacy Policy',
            url: "https://docs.google.com/document/d/11c4u6deHytCvKp826drQrLiWTxBrRq0iUC-2HcfzgTE/edit",
        },
        "terms": {
            title: 'Terms of use',
            url: "https://docs.google.com/document/d/11WXVc_khCQFWvk-2XetZeAPofwD_0idRbLMY_ryixec/edit",
        },
        "whitepaper":
        {
            title: 'Whitepaper',
            url: "https://drive.google.com/file/d/1nWLDbb8YwZSZKShxMaNxqex-_tttBXhR/view?usp=drivesdk"
        },
    };

    // Toggle the popup visibility
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Breadcrumb icon that toggles the popup */}
            <FaBars className="breadcrumb-icon" onClick={handleToggle} />

            {/* Fullscreen popup */}
            {isOpen && (
                <div className="breadcrumb-popup">
                    <div className="breadcrumb-popup-header">
                        <FaTimes className="close-icon" onClick={handleToggle} />
                    </div>
        <Link to="/about" className="custom-link-header breadcrumb-item" onClick={handleToggle} >About Us</Link>

                    <div className="breadcrumb-popup-content">
                        
                    <span key={'whitepaper'} onClick={() => window.open(footerUrls['whitepaper'].url, '_blank')}
                            className="breadcrumb-item">
                            {footerUrls['whitepaper'].title}
                        </span>


                        <span key={'terms'} onClick={() => window.open(footerUrls['terms'].url, '_blank')} className="breadcrumb-item">
                            {footerUrls['terms'].title}
                        </span>
                        <span key={'privacy'} onClick={() => window.open(footerUrls['privacy'].url, '_blank')} className="breadcrumb-item">
                            {footerUrls['privacy'].title}
                        </span>
                        <span key={'cookies'} onClick={() => window.open(footerUrls['cookies'].url, '_blank')} className="breadcrumb-item">
                            {footerUrls['cookies'].title}
                        </span>

                        <button className='connect-wallet-header' onClick={addTokenToMetaMask}>Import VES token </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Breadcrumb;
