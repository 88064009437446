import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import BuyVES from './components/BuyVES';
// import ExchangeRates from './components/ExchangeRates';
// import TransactionHistory from './components/TransactionHistory';
import Footer from './components/Footer';
import AboutPage from './components/about'; // Make sure to import all your components
import { WalletProvider } from './Context/WalletContext';
import './styles/App.css';
import { Buffer } from 'buffer';
import process from 'process';

window.Buffer = Buffer;
window.process = process;


function App() {
  return (
    <WalletProvider>
      <Router>
        <div className="App">
          <Header />
          <div className="content">
            <Routes>
              <Route path="/" element={<BuyVES />} />
              {/* <Route path="/exchange-rates" element={<ExchangeRates />} />
              <Route path="/transaction-history" element={<TransactionHistory />} /> */}
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </WalletProvider>
  );
}

export default App;
