import '../styles/footer.css';
import Facebook from '../assets/facebook.svg';
import sectigo from "../assets/sectigo_trust_seal.png";
import X from '../assets/x.svg';
import Telegram from '../assets/telegram.svg';

import Discord from "../assets/discord.svg";
import { useState, useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import footerUrls from '../constants/urls';


export default function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    }
  });

  return (
    <>
      <div class="footer-container">
        <div class="footer-column">
          <div class="heading">VESTION</div>
          <div class="footer-row">
            {/* <p style={{ color: "white" }}>An ERC-20 Green upgrade from Bitcoin that combines deflationary features with core utilities.
            </p> */}
          </div>
          <div className="footer-row">
            <a href="https://t.me/vestionofficial" target="_blank" rel="noopener noreferrer">
              <img src={Telegram} alt="Telegram" />
            </a>
            <a href="https://x.com/Vestiontoken" target="_blank" rel="noopener noreferrer">
              <img src={X} alt="X (Twitter)" />
            </a>
            {/* <a href="https://github.com/vestiontoken" target="_blank" rel="noopener noreferrer">
        <img src={Github} alt="Github" />
      </a> */}
            <a href="https://www.facebook.com/vestiontoken" target="_blank" rel="noopener noreferrer">
              <img src={Facebook} alt="Facebook" />
            </a>

            <a href="https://discord.com/invite/Ygg58r67Hv" target="_blank" rel="noopener noreferrer">
              <img src={Discord} alt="Discord" />
            </a>

          </div>
        </div>
        {
          isMobile ?
            <></>
            :
            <>

              <div class="footer-column">
                <div class="heading" style={{ color: "white" }}>DOCUMENTS</div>
                <div class="footer-row custom-link">
                  <a
                    href={footerUrls["whitePaper"]}
                    className='custom-link'
                    target="_blank"
                    rel="noopener noreferrer"
                  >WHITEPAPER </a>

                </div>
                <div class="footer-row custom-link">
                  <a
                    href={footerUrls["terms"]}
                    className='custom-link'
                    target="_blank"
                    rel="noopener noreferrer"
                  >TERMS OF USE</a>

                </div>
                <div class="footer-row custom-link" >
                  <a
                    href={footerUrls["privacy"]}
                    className='custom-link'
                    target="_blank"
                    rel="noopener noreferrer"
                  >PRIVACY POLICY</a>

                </div>
                <div class="footer-row custom-link">
                  <a
                    href={footerUrls["cookies"]}
                    className='custom-link'
                    target="_blank"
                    rel="noopener noreferrer"
                  >COOKIES POLICY</a>

                </div>
              </div>
              <div class="footer-column">
                <div class="heading">CONTACT</div>
                <div class="footer-row">
                  <a href='mailto:admin@vestion.net' style={{ color: "#E4B32B" }} > admin@vestion.net </a>
                </div>
                <div class="footer-row">
                  <img src={sectigo} />
                </div>
              </div>
            </>
        }




      </div>
      <div class="footer">
        &copy; 2024 COPYRIGHT VESTION. ALL RIGHTS RESERVED
      </div>
    </>
  );
}


{/* <div class="footer-column">
      <div class="heading"  style={{color: "white"}}>LINKS</div>
      <div class="footer-row" style={{color: "white"}}>
      <Link to="/about" className="custom-link" >ABOUT</Link>
      className={activeLink === '/' ? "active-link" : "nav-bar-item"}
        </div>

      <div class="footer-row"  style={{color: "white"}}>HOME</div>
      <div class="footer-row" style={{color: "white"}}>BUY VESTION</div> 
      <div class="footer-row" style={{color: "white"}}>ROAD MAP</div>
      <div class="footer-row" style={{color: "white"}}>FAQ</div>

  </div> */}