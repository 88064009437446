import React, { useState, useContext, useEffect } from 'react';
import '../styles/buyVES.css';
import { WalletContext } from '../Context/WalletContext';
import swap_logo from '../assets/swap_logo.svg';
import VES_symbol from '../logos/Grouplogo.png';
import ETH_token from '../logos/eth-token.png';
//import TextField from '@mui/material/TextField';
import TokenComponent from './tokenComponent';

function BuyVES() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { connected, supply, cost, price, connectWallet, rate, getAmount, handleBuyTokens, transactionStatus, ethPrice } = useContext(WalletContext);
    const [amount, setAmount] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [estimatedCost, setEstimatedCost] = useState('');
    const [ethQuantity, setEthQuantity] = useState(0);
    const [vesQuantity, setVesQuantity] = useState(0);

    useEffect(() => {
        setAmount(amount);
        console.log("amount updated", amount);
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Set up the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [amount]);

    const handleEthChange = (e) => {
        let value = parseFloat(e.target.value);
        if (isNaN(value) || price === '' || ethPrice === '') return;
        setEthQuantity(value);
        const vesAmount = value / parseFloat(price);
        setVesQuantity(vesAmount);
        setEstimatedCost(ethPrice * value);
        setAmount(vesAmount);
    }

    const handleVesChange = (e) => {
        setEthQuantity('');
        let value = parseFloat(e.target.value);
        if (isNaN(value) || price === '' || rate === '') return;
        setVesQuantity(value);

        const ethAmount = value * parseFloat(price);
        console.log("VES quantity: ", vesQuantity);
        console.log("eth quantity: ", ethAmount);
        setEthQuantity(ethAmount);      //price is the value of token in eth        

        setEstimatedCost(rate * value);     // rate is the cost of tokens in dollars
        setAmount(value);
    }
    useEffect(() => {
        console.log("Transaction Status: ", transactionStatus);
    }, [transactionStatus]);

    // const handleAmountChange = (e) => {
    //     if (!connected) {
    //         alert("you need to connect your wallet first");
    //     }
    //     const newAmount = e.target.value;       // new amount is the numbe of tokens to buy 
    //     e.preventDefault();
    //     setAmount(newAmount);
    //     getAmount(newAmount);
    //     setEstimatedCost(rate * newAmount);
    // };

    const handleBuy = () => {
        if (!connected) {
            console.error("You need to connect the wallet first");
            alert("you need to connect your wallet first");
            return;
        }
        // if (!agreed) {
        //     console.error("You need to agree to the Token Purchase Agreement");
        //     return;
        // }
        const amountNumber = Number(amount);

        if (!Number.isInteger(amountNumber)) {
            console.error("Input an integer in the VES section");
            alert("Input an integer in the VES section");
            return;
        }
        console.log(amountNumber);
        handleBuyTokens(amount);
    };

    return (
        <div className="buy-tokens-container">
            <div className='buy-title'>
                <h1>
                    Your Gateway To<br />Digital <span> Investments</span>
                </h1>
                {/* desktop */}
                {
                    isMobile ?
                        <div style={{ paddingBottom: "8px" }}>
                            <span style={{ fontSize: "10px",color: "#FFF" }}> *During presales, Vestion is available via MetaMask
                                and other Web3 wallets on our website to ensure a swift process.
                                You can sign up for MetaMask
                                <span> <a style={{ color: "#E4B325" }} href="https://metamask.io">here</a></span>.
                                Click this link to <a href="dapp://www.vestion.digital" style={{ color: "#E4B325" }} >
                                    connect your wallet</a>
                            </span>
                        </div>
                        :
                        <div style={{ paddingBottom: "8px" }}>
                            <span style={{ fontSize: "10px", color: "#FFF" }}> *During presales to ensure a swift process Vestion is available via the metamask and other Web3 wallets on our website.
                                You can sign up for metamask
                                <span style={{ color: "#FFF" }}> <a style={{ color: "#E4B325" }} href="https://metamask.io">here</a> then click the connect button in the top right corner. For best results we recommend using Google Chrome with the
                                    metamask extension available <a href="https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" style={{ color: "#E4B325" }} >here</a> or scan the QR code.
                                </span>
                            </span>
                        </div>
                }
            </div>
            <div className="buy-ves">
                <div className='payment-section'>
                    <div className='input-group'>
                        <div className='component-heading'>
                            <img src={swap_logo} className='swap-logo' />
                            <p className='component-heading-text'>Buy tokens</p>
                        </div>
                        <label className='label'>You pay</label>
                        <div className='input-container'>
                            <TokenComponent
                                handleEthChange={handleEthChange}
                                handleVesChange={handleVesChange}
                                ethQuantity={ethQuantity}
                                vesQuantity={vesQuantity}
                                estimatedCost={estimatedCost}
                            />
                            {/* <TextField>
                            </TextField> */}
                            {/* <label className='you-pay'>{Number(cost).toFixed(5)} ETH</label>
                            <div className='currency-symbol'>
                                <img src={ETH_token} alt='VES' className='currency-icon' />
                                <span className='currency-text'>ETH</span>
                            </div> */}
                        </div>
                        {/* <label className='estimated-cost'>Estimated cost: ~${Number(estimatedCost).toFixed(2)}</label> */}
                    </div>
                    {/* <p className='arrow'>--</p>
                    <div className='input-group'>
                        <label className='label'>You get</label>
                        <div className='input-container'>
                            <input className='you-get' type="number" value={amount} onChange={handleAmountChange} placeholder="Enter token amount..." />
                            <div className='currency-symbol'>
                                <img src={VES_symbol} alt='VES' className='currency-icon' />
                                <span className='currency-text'>VES</span>
                            </div>
                        </div>
                        <label className='total-supply'>Total supply: {supply} VES</label>
                    </div> */}
                </div>
                {/* <div className='bottom-section'>
                    {transactionStatus && <p>{transactionStatus}</p>}
                    <div className="agreement">
                        <input type="checkbox" id="agreement" checked={agreed} onChange={(e) => setAgreed(e.target.checked)} />
                        <label htmlFor="agreement" className='agreement-text'>By continuing I agree to the Token Purchase Agreement</label>
                    </div>
                    <button className="buy-button" onClick={handleBuy}>Buy VES</button>
                </div> */}
                <div>{ }</div>
                <div>{transactionStatus && <p>{transactionStatus}</p>}</div>
                <button className="connect-wallet-home" onClick={connected ? handleBuy : connectWallet}>
                    {connected ? "Buy VES" : "Connect Wallet"}
                </button>
            </div>
            <p style={{ fontSize: "10px" }}>*For alternative methods of purchase including fiat and other cryptocurrencies please contact us directly at <span ><a href='mailto:payments@vestion.net' style={{ color: "#E4B32B" }} > payments@vestion.net </a> </span> </p>
        </div>
    );


}

export default BuyVES;
