import React, { useContext,useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation hook
import { WalletContext } from '../Context/WalletContext';
import logo from '../logos/Grouplogo.png';
import '../styles/header.css';
import mobile_connect from '../assets/mobile_connect.png';
import Breadcrumb from './breadcrumbs';


function Header() {
    const { connected, connectWallet, balance, disconnectWallet, addTokenToMetaMask } = useContext(WalletContext);
    const location = useLocation(); // Get the current location
    const [hovered, setHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Check if the URL path contains 'about'
    const shouldHideWalletSection = location.pathname.includes('about');

    const handleButtonClick = () => {
       connectWallet(); 
        // if(isMobile){
        //     window.location.href = 'dapp://www.vestion.digital';
        // }
        // else{
        // }
    };
    
    return (
        <header className="header">
            <div className='left-components'>
            <Link to="/" className="logo-link">
            <img src={logo} className='img-logo' alt="Logo" />
            <h1 className='logo-text'>Vestion</h1>
        </Link>
                {!(window.innerWidth <= 768) &&
            <>
        <Link to="/about" className="custom-link-header" >ABOUT US</Link>
            <Link to="/" title='Coming soon' className="custom-link-header" >ROADMAP</Link>
            <Link to="/" title='Coming soon' className="custom-link-header" >TEAM INFO</Link>
            </>
                }
            </div>


            <div className='wallet-section'>

                {connected ? (
                    <button className="connect-wallet-header disconnect" onClick={disconnectWallet}>Disconnect Wallet</button>
                ) : (
                    <button className="connect-wallet-header" onClick={handleButtonClick}>Connect Wallet</button>
                )}
                {/* <button className='connect-wallet-header' onClick={addTokenToMetaMask}>Import VES token </button> */}
                {/* <button className="connect-wallet-header" onClick={connectWallet}>
                    {connected ? 'Disconnect' : 'Connect Wallet'}
                </button> */}
                {balance && balance !== '' && <p className='balance'> {balance} ETH</p>}
                {!(window.innerWidth <= 768 ) && !connected &&
<>                
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                   onMouseEnter={() => setHovered(true)} 
                   onMouseLeave={() => setHovered(false)}
                >
                <title>Connect Wallet on Mobile</title>
                <path d="M22 1.25h-12c-1.518 0.002-2.748 1.232-2.75 2.75v24c0.002 1.518 1.232 2.748 2.75 2.75h12c1.518-0.002 2.748-1.232 2.75-2.75v-24c-0.002-1.518-1.232-2.748-2.75-2.75h-0zM23.25 28c-0.001 0.69-0.56 1.249-1.25 1.25h-12c-0.69-0.001-1.249-0.56-1.25-1.25v-24c0.001-0.69 0.56-1.249 1.25-1.25h12c0.69 0.001 1.249 0.56 1.25 1.25v0zM18 25.75h-4c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h4c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
            </svg>
            
            {hovered && (
                <div className="info-box">
                    Scan the QR Code to open Vestion on your Mobile
                </div>
            )}


</>

}
{
    (window.innerWidth <= 768 ) && <Breadcrumb addTokenToMetaMask={addTokenToMetaMask}/>
}

            </div>
        </header>
    );
}

export default Header;
