import React, { createContext, useState, useEffect } from 'react';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { Web3Provider } from "@ethersproject/providers";
import Exchange_abi from '../abis/Exchange_abi.json';
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
    walletlink: {
        options: {
            appName: "Web3 Modal",
            infuraId: process.env.REACT_APP_INFURA_ID
        }
    },
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: process.env.REACT_APP_INFURA_ID,
        },
    },
};

export const WalletContext = createContext(null);

export const WalletProvider = ({ children }) => {
    const [provider, setProvider] = useState(null);
    const [connected, setConnected] = useState(false);
    const [signer, setSigner] = useState(null);
    const [balance, setBalance] = useState('');
    const [contract, setContract] = useState(null);  // Changed to null initially
    const [price, setPrice] = useState('');
    const [ethPrice, setEthPrice] = useState('');
    const [rate, setRate] = useState('');
    const [supply, setTotalSupply] = useState('');
    const [transactionStatus, setTransactionStatus] = useState('');
    const [tokensToBuy, setTokensToBuy] = useState('');
    const [cost, setCost] = useState('');
    const [transactionDetails, setTransactionDetails] = useState('');
    const [newTransaction, setNewTransaction] = useState(false);
    const [something, setSomething] = useState('');

    const contractAddress = '0x4025037fBee1708F1E1AdD8D80f1EB84bb0b6d16';

    useEffect(() => {
        console.log('price updated: ', price);
    }, [price])

    useEffect(() => {
        console.log('rate updated: ', rate);
        setRate(rate);
    }, [rate])

    useEffect(() => {
        console.log('got eth price', ethPrice);
        setEthPrice(ethPrice);
    }, [ethPrice]);

    useEffect(() => {
        if (contract && signer) {
            const handleTokensBought = async (buyer, tokensBought) => {
                const userAddress = await signer.getAddress();
                if (buyer.toLowerCase() === userAddress.toLowerCase()) {
                    setTransactionStatus('Transaction successful!');
                    console.log("transaction details: ", buyer, Number(tokensBought));
                    setTransactionDetails([buyer, tokensBought.toString()]);
                    alert(`transaction successful! address: ${buyer} || tokens: ${Number(tokensBought)}`);
                }
            };

            contract.on("tokensBought", handleTokensBought);

            return () => {
                contract.off("tokensBought", handleTokensBought);
            };
        }
    }, [contract]);

    const addTokenToMetaMask = async () => {
        if (!connected) {
            alert('Connect your wallet first');
            return;
        }
        const tokenAddress = '0xcC97c17aF0f3446c635FfaF8FBeC8899d67a16d3';
        const tokenSymbol = 'VES';
        const tokenDecimals = 18;
        const tokenImage = 'https://etherscan.io/token/images/vestionnet_32.png';

        try {
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: tokenImage,
                    },
                },
            });

            if (wasAdded) {
                console.log('Token was added to MetaMask');
                alert('Token successfully added to MetaMask!');
            } else {
                console.log('Token addition failed');
            }
        } catch (error) {
            console.error('Error adding token:', error);
        }
    };

    async function connectWallet() {
        try {
            console.log("Attempting to connect to wallet...");
            
            const web3Modal = new Web3Modal({
                cacheProvider: false,
                providerOptions,
            });
    
            const web3ModalInstance = await web3Modal.connect();
            const web3ModalProvider = new Web3Provider(web3ModalInstance);
            const signer = web3ModalProvider.getSigner();
    
            // Check if signer is valid
            if (!signer) {
                throw new Error("Signer is undefined");
            }
    
            const Contract = new ethers.Contract(contractAddress, Exchange_abi, signer);
            if (!Contract) {
                throw new Error("Contract is undefined");
            }
            
            setContract(Contract);
            setProvider(web3ModalProvider);
            setSigner(signer);
            setConnected(true);
            await getBalance(signer, web3ModalProvider);
    
            const [rateValue, priceValue, supply, ethercost] = await Promise.all([
                getRate(Contract),
                getPrice(Contract),
                getTotalSupply(Contract),
                getEtherPrice(Contract)
            ]);
    
            setRate(rateValue);
            setPrice(priceValue ? priceValue.toString() : '');
            setTotalSupply(supply);
            setEthPrice(ethercost);
            
        } catch (error) {
            console.error("Error in connectWallet:", error);
            alert("An error occurred while connecting to the wallet. Please try again.");
        }
    }
    

    const disconnectWallet = async () => {
        try {
            // Clear all wallet-related state
            setProvider(null);
            setSigner(null);
            setConnected(false);
            setBalance('');
            setContract(null);
            setPrice('');
            setEthPrice('');
            setRate('');
            setTotalSupply('');
            setTransactionStatus('');
            setTokensToBuy('');
            setCost('');
            setTransactionDetails('');
            setNewTransaction(false);

            // If using Web3Modal with cacheProvider: true, you need to clear the cache
            const web3Modal = new Web3Modal({
                cacheProvider: false, // Set to false if you don't want to cache the provider
                providerOptions,
            });

            // Clear cached provider if any
            web3Modal.clearCachedProvider();

            // Optionally, if you are using a wallet like MetaMask, you may want to trigger a disconnection
            if (provider && provider.provider && provider.provider.disconnect) {
                await provider.provider.disconnect();
            }

            console.log("Wallet disconnected");
        } catch (error) {
            console.error("Error disconnecting wallet:", error);
        }
    }

    async function getBalance(Signer, Provider) {
        try {
            const address = await Signer.getAddress();
            const balance = await Provider.getBalance(address);
            const balanceInEth = (parseFloat(balance.toString() / (10 ** 18))).toFixed(4);
            setBalance(balanceInEth);
        } catch (error) {
            console.error(error);
        }
    }

    const getPrice = async (contract) => {
        try {
            let currentPrice = await contract.getPrice();
            let priceInEth = Number(currentPrice) / 10 ** 18;
            return priceInEth;
        } catch (error) {
            console.error("error getting the price: ", error);
            return null;
        }
    };

    const getEtherPrice = async (contract) => {
        try {
            // Get the raw BigNumber value from the contract
            let etherPrice = await contract.getEthPrice();

            // Convert BigNumber to string
            let etherPriceString = etherPrice.toString();

            // Determine the number of decimal places (18 in this case)
            const decimals = 18;

            // Split the string into integer and fractional parts
            const integerPart = etherPriceString.slice(0, -decimals) || '0';
            const fractionalPart = etherPriceString.slice(-decimals).padStart(decimals, '0');

            // Combine integer and fractional parts
            const formattedPrice = `${integerPart}.${fractionalPart}`;

            // Convert to a number and format to 4 decimal places
            const priceInDecimal = parseFloat(formattedPrice);
            const priceFormatted = priceInDecimal.toFixed(4).toString();

            console.log('Ether Price (Raw):', etherPriceString);
            console.log('Ether Price (Processed):', priceFormatted);
            return priceFormatted;
        } catch (error) {
            console.error('Error getting ether price:', error);
        }
    };


    const getRate = async (contract) => {
        try {
            let currentRate = await contract.rate();
            const temp = 1 / Number(currentRate);
            return temp.toString();
        } catch (error) {
            console.error("error getting the rate:", error);
            return null;
        }
    }

    const getTotalSupply = async (contract) => {
        try {
            let currentSupply = await contract.totalDeposited();
            return Number(currentSupply);
        } catch (error) {
            console.error("error getting the total supply", error);
            return null;
        }
    }

    const getAmount = async (tokensToBuy) => {
        try {
            setTokensToBuy(tokensToBuy);
            let amount = await contract.getAmount(tokensToBuy);
            console.log('calculating amount to pay...')
            let amountToPay = Number(Number(amount) / 10 ** 18);
            setCost(amountToPay);
            console.log(amountToPay);
        } catch (error) {
            console.error('error getting the amount: ', error);
        }
    }

    const handleBuyTokens = async (tokensToBuy) => {
        setTransactionStatus('transaction pending...');
        try {
            const value = await contract.getAmount(tokensToBuy);
            await contract.buyTokens(tokensToBuy, { value });
            setTokensToBuy('');
            setNewTransaction(!newTransaction);  // Toggle to trigger useEffect
            const updatedSupply = await getTotalSupply(contract);
            setTotalSupply(updatedSupply);
            await getBalance(signer, provider);
        } catch (error) {
            console.error('error buying tokens:', error.code);
            //alert(error.message);
            setTransactionStatus('');
            alert('error buying tokens: ' + error.code);

        }
    }

    return (
        <WalletContext.Provider value={{ provider, connected, signer, balance, price, rate, supply, transactionStatus, transactionDetails, tokensToBuy, cost, ethPrice, connectWallet, getAmount, handleBuyTokens, disconnectWallet, addTokenToMetaMask }}>
            {children}
        </WalletContext.Provider>
    );
};
